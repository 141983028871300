import React, {createContext, useContext, useState, useMemo} from 'react';
import useEffectOnce from "../hooks/useEffectOnce";
import {useModal} from "./ModalContext";
import axiosInstance from "../helpers/axios";
import { TrackJS } from "trackjs";

const profileContextDefaultValues = {
    data: {},
    isLoading: true,
    loggedIn: false,
};

const ProfileContext = createContext({
    profileContextDefaultValues,
    setProfile: () => {}
});

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState(profileContextDefaultValues);
    const value = useMemo(
        () => ({ profile, setProfile }),
        [profile]
    );

   const {show, hide} = useModal();

   useEffectOnce(()=>{
       if(localStorage.getItem('auth_key')){
           getProfile();
       }else{
           TrackJS.configure({
               userId: 'not_authorized'
           });
           resetProfileData();
       }
   });

    const resetProfileData = () => {
        setProfile({
            data: {},
            isLoading: false,
            loggedIn: false,
        });
    }

    const getProfile = async(auth_key = null) => {
        try {
            const response = await axiosInstance.get('getProfile');
            if(response.data.result){
                setProfile({
                    ...profile,
                    data: response.data.data,
                    isLoading: false,
                    loggedIn: true,
                });

                if(window.promoTools){
                    window.promoTools.checkGrabberWidget.settings = {
                        skin: 'custom',
                        api: process.env.REACT_APP_API_PROXY,
                        method: 'widgetsReceipts',
                        userUuid: response.data.data.profile.userUuid,
                        i18n: {
                            qr: {
                                title: 'Сканирование',
                                subTitle: 'Наведите камеру на QR-код',
                                btnQr: 'Сканировать QR-код чека',
                                btnManual: 'Ввести данные вручную',
                                btnPhotos: 'Загрузить фото чека',
                                btnBackScanner: 'Назад к сканеру чеков',
                                cameraError: {
                                    title: 'Мы не можем получить доступ к камере устройства. Разрешите браузеру обращаться к камере или загрузите фото чека',
                                },
                            },
                            manual: {
                                title: 'ввести данные <br/>с чека вручную',
                                subTitle: '',
                            },
                            photos: {
                                title: 'загрузка чека',
                                subTitle: 'Добавьте фото чека',
                                btnPhotos: 'Отправить',
                            },
                            pending: {
                                title: 'Подождите!',
                                subTitle: 'Идет отправка чека',
                            },
                            fail: {
                                title: 'Не удалось <br/>отправить чек!',
                            },
                            success: {
                                title: '<i class="icon__success"></i><div>ваш чек успешно зарегистрирован</div>',
                                subTitle: 'Информация о&nbsp;результатах розыгрыша доступна в&nbsp;разделе Загруженные чеки',
                            },
                        },
                        events: {
                            onSentSuccess(res) {
                                let elUp1 = document.getElementById('update-codes');
                                if(elUp1){
                                    elUp1.click();
                                }

                                let elUp2 = document.getElementById('inc-codes');
                                if(elUp2){
                                    elUp2.click();
                                }
                            },
                        },
                    };
                }
                TrackJS.configure({
                    userId: response.data.data.profile.email ? response.data.data.profile.email : response.data.data.profile.userUuid
                });
            }
        } catch (error) {
            TrackJS.configure({
                userId: 'not_authorized'
            });
            if(error.response.data){
                /*resetProfileData();
                localStorage.removeItem('auth_key');
                window.location.reload();*/
            }
        }
    }

  return (
    <ProfileContext.Provider value={value}>
        {useMemo(() => (
            children
        ), [])}
    </ProfileContext.Provider>
  )
}

export function useProfile() {
  return useContext(ProfileContext)
}