import {useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

function ChangePass(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { show, hide} = useModal();

    const schema = yup.object({
        pass: yup.string().min(6, 'Мин. 6 символов').required(),
        passR: yup.string().min(6, 'Мин. 6 символов').oneOf([yup.ref('pass'), null], 'Пароли не совпадают'),
    }).required();

    const { register, setFocus, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchPass2 = watch("pass");
    const watchPass3 = watch("passR");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("pass", data.pass);
        formData.append("passR", data.passR);

        try {
            const response = await axiosInstance.post('updateProfile', formData);
            if(response.data.result){
                show(<Info
                        icon={true}
                        text={'Пароль успешно обновлен'}
                        close={hide}/>,
                    "modal--info"
                );
            }else{
                if(response.data.error) {
                    Object.keys(response.data.error).map((err, i) => {
                        setError(err, { type: 'custom', message: response.data.error[err][0] });
                    });
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="d-flex justify-content-center">
                        <div className="sc__title-wrap">
                            <div className="modal__title gradient-text">
                                сменить пароль
                            </div>
                            <span className="star__light-decor star__light-decor--1"/>
                            <span className="star__light-decor star__light-decor--2"/>
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.pass ? ' has-error' : '') + ((watchPass2 && watchPass2 != '') ? ' has-value' : '')}>
                                        <input
                                            type="password"
                                            id="pass"
                                            autoComplete="current-password"
                                            {...register("pass")}
                                        />
                                        <label htmlFor="pass" className="form__label">
                                            Новый пароль
                                        </label>
                                    </div>
                                    {errors.pass && (
                                        <p className="error__message">
                                            {errors.pass?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.passR ? ' has-error' : '') + ((watchPass3 && watchPass3 != '') ? ' has-value' : '')}>
                                        <input
                                            type="password"
                                            id="passR"
                                            autoComplete="current-password"
                                            {...register("passR")}
                                        />
                                        <label htmlFor="passR" className="form__label">
                                            Новый пароль еще раз
                                        </label>
                                    </div>
                                    {errors.passR && (
                                        <p className="error__message">
                                            {errors.passR?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action d-flex justify-content-center">
                                    <button disabled={isSubmitting} type="submit" className="btn-ct btn--full d-flex align-items-center justify-content-center">
                                        <span>Изменить пароль</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ChangePass;
