import React, {useEffect, useLayoutEffect, useCallback, useState} from "react";
import "keen-slider/keen-slider.min.css"
import axiosInstance from "../helpers/axios";
import { DndProvider } from 'react-dnd'
import {isMobile, isTablet} from 'react-device-detect';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import update from 'immutability-helper'
import parse from "html-react-parser";
import {DragItem} from "../components/DragItem";
import anime from "animejs";
import {useModal, useProfile} from "../context";
import Info from "../components/modals/Info";
import {Navigate} from "react-router-dom";

const draggableList = [
    {
        id: 1,
        name: 'Термокружки'
    },
    {
        id: 2,
        name: 'Толстовки'
    },
    {
        id: 3,
        name: 'Брендированная техника'
    },
    {
        id: 4,
        name: 'Сумки'
    },
    {
        id: 5,
        name: 'Футболки'
    },
    {
        id: 6,
        name: 'Зонты'
    },
    {
        id: 7,
        name: 'Головные уборы'
    },
    {
        id: 8,
        name: 'Платки'
    },
    {
        id: 9,
        name: 'Декор для дома'
    }
];

function Quiz() {
    const [loading, setLoading] = useState(true);
    const [end, setEnd] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [prevQuizValue, setPrevQuizValue] = useState(0);
    const [quiz, setQuiz] = useState(1);
    const [quizVar, setQuizVar] = useState(0);
    const [quizMultiple, setQuizMultiple] = useState({
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null,
        11: null,
    });
    const [quizMultipleOpen, setQuizMultipleOpen] = useState(0);
    const [list, setList] = useState(draggableList);
    const {profile, setProfile} =  useProfile();
    const { show, hide} = useModal();

    useEffect(() => {
        if(localStorage.getItem('auth_key')){
            if(!profile.isLoading && profile.data){
                setLoading(false);

                if(profile.data.lastQuiz && profile.data.lastQuiz.question_id){
                    if(parseInt(profile.data.lastQuiz.question_id) < 12){
                        setQuiz(parseInt(profile.data.lastQuiz.question_id) + 1);
                        if(profile.data.lastQuiz.question_id != 10 && profile.data.lastQuiz.question_id != 4){
                            setPrevQuizValue(parseInt(profile.data.lastQuiz.answer));
                        }
                    }else{
                        setEnd(true);
                    }
                }
            }
        }
    },[profile]);

    useLayoutEffect(()=>{
        if(window.matchMedia("(min-width: 1024px)").matches){
            anim_generator();
        }
    }, []);

    const anim_generator = () => {
        let anim_generator_tl = anime.timeline();
        anim_generator_tl.add({
            targets: '#inner-line-1-path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(.5, .05, .1, .3)',
            duration: 450,
            delay: function(el, i) { return i * 150 },
        }).add({
            targets: '.inner__svg-icon-1,.inner__svg-icon-2,.inner__svg-icon-3,.inner__candy-1,.inner__leaf-1',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        }).add({
            targets: '#inner-line-2-path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(.5, .05, .1, .3)',
            duration: 450,
            delay: function(el, i) { return i * 150 },
        }).add({
            targets: '.inner__svg-icon-4,.inner__svg-icon-5',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        }).add({
            targets: '#inner-line-3-path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(.5, .05, .1, .3)',
            duration: 450,
            delay: function(el, i) { return i * 150 },
        }).add({
            targets: '.inner__svg-icon-6,.inner__svg-icon-7,.inner__candy-2',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        }).add({
            targets: '.anim-generator-text',
            scale: [0.45, 1],
            opacity: [0, 1],
            duration: 200,
            easing: 'easeOutCubic',
            delay: anime.stagger(100)
        });
    }

    const moveItem = useCallback((dragIndex, hoverIndex) => {
        setList((prevList) =>
            update(prevList, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevList[dragIndex]],
                ],
            }),
        )
    }, [])
    const renderItem = useCallback((it, index) => {
        return (
            <DragItem
                key={it.id}
                index={index}
                id={it.id}
                text={it.name}
                moveCard={moveItem}
            />
        )
    }, []);

    const answerQuiz = async() => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("quest_id", quiz);

        let _answer = quizVar;

        if(quiz === 4){
            _answer = JSON.stringify(quizMultiple);
        }

        if(quiz === 10){
            _answer = JSON.stringify(list);
        }

        formData.append("answer", _answer);

        /*if(quiz < 12){
            setQuiz(parseInt(quiz) + 1);
            if(quiz != 10 && quiz != 4){
                setPrevQuizValue(parseInt(_answer));
            }
        }else{
            setEnd(true);
        }

        setSubmitting(false);*/

        try {
            const response = await axiosInstance.post('answerQuiz', formData);
            if(response.data){
                setPrevQuizValue(_answer);
                setQuizVar(0);
                if(quiz < 12) {
                    setQuiz(quiz + 1);
                }else{
                    show(<Info title="Опрос завершен!"
                               text="Наша команда благодарит вас за&nbsp;участие в&nbsp;акции и&nbsp;за&nbsp;прохождение опроса!"
                               close={hide}/>,
                        "modal--info");
                    setProfile({
                        ...profile,
                        data: {
                            ...profile.data,
                            lastQuiz: {
                                ...profile.data.lastQuiz,
                                question_id: 12,
                            }
                        }
                    });
                    setEnd(true);
                    setTimeout(function (){
                        window.location.href = '/';
                    }, 4000);
                }
            }
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/" replace />)
    }

    return (
        <div className="content">
            <div className="sc__generator sc__quiz">
                <div className="lk__decor-4"/>
                <div className="lk__decor-5"/>
                <div className="star__decor star__decor--quiz desk-only"/>
                <div className="container">
                    <div className={'modal__ct quiz-form' + (submitting ? ' form--loading' : '')}>
                        {!loading && !end && (
                            <>
                                <div className="modal__ct-box fadeIn animated">
                                    <div className="quiz__current">
                                        <div className="quiz__current-span gradient-text">
                                            {quiz}/12
                                        </div>
                                    </div>
                                    {quiz === 1 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title text-center gradient-text">
                                                <span>Уточните</span> Ваш возраст
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['18-24', '25-34', '35-44', '45-54', '55+'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    {quiz === 2 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text">
                                                <span>Покупаете</span>&nbsp;ли вы&nbsp;коробку конфет Коркунов для себя (к&nbsp;чаю/ порадовать себя)?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Да', 'Нет'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 3 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Дарите</span>&nbsp;ли вы&nbsp;конфеты мужчинам?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Да', 'Нет'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 4 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title text-center gradient-text">
                                                <span>На&nbsp;какие праздники чаще</span> покупаете конфеты Коркунов?
                                            </div>
                                            <div className="quiz__descr text-center">
                                                Поставьте цифру от&nbsp;&laquo;1&raquo; до&nbsp;&laquo;11&raquo; в&nbsp;порядке приоритетности, где &laquo;11&raquo; будет очень часто, а&nbsp;&laquo;1&raquo; редко
                                            </div>
                                            <div className="quiz__grid quiz__grid--cols">
                                                {['14 февраля', 'День Учителя', '23 февраля', 'Новый год', '8 марта', 'День рождения близкого человека', 'Последний звонок', 'Способ отблагодарить кого-то', 'Выпускной', 'Когда собираюсь в&nbsp;гости', 'Первое сентября'].map((it, i) => (
                                                    <div className={'quiz__it-select' + (quizMultipleOpen === (i + 1) ? ' open' : '') + (quizMultiple[i + 1] ? ' has-value' : '')}>
                                                        <div onClick={()=>{
                                                            setQuizMultipleOpen(quizMultipleOpen === (i + 1) ? 0 : (i + 1));
                                                        }} className={'quiz__it-select-input d-flex align-items-center' + (quizVar === (i + 1) ? ' active' : '') + (i > 7 ? ' quiz__it-select-input--large' : '')}>
                                                            <div className="quiz__it-toggle d-flex align-items-center">
                                                                <div className="quiz__it-small">
                                                                    {quizMultiple[i + 1] ?? ''}
                                                                </div>
                                                                <div className="quiz__it-toggle-arrow">
                                                                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 1L5 5L9 1" stroke="#8E1348" strokeWidth="2"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="quiz__it-val">
                                                                {parse(it)}
                                                            </div>
                                                        </div>
                                                        <div className="quiz__it-select-dropdown">
                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((drIt, j) => (
                                                                <div onClick={()=>{
                                                                    if(!Object.values(quizMultiple).filter(value => value !== null).includes(drIt)) {
                                                                        setQuizMultiple({
                                                                            ...quizMultiple,
                                                                            [i + 1] : drIt
                                                                        });
                                                                    }else {
                                                                        setQuizMultiple({
                                                                            ...quizMultiple,
                                                                            [i + 1] : null
                                                                        });
                                                                    }

                                                                    setQuizMultipleOpen(0);

                                                                }} className={'quiz__it-select-dropdown-it' + (Object.values(quizMultiple).filter(value => value !== null).includes(drIt) ? ' locked': '') + (drIt === quizMultiple[i + 1] ? ' active' : '')}>
                                                                    <span>{drIt}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 5 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Знаете&nbsp;ли вы, что вы&nbsp;можете</span> дополнить коробку интереактивным поздравлением, отсканировав QR-код?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Да', 'Нет'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 6 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Создавали&nbsp;ли Вы&nbsp;интерактивное поздравление</span> (сканирование&nbsp;QR кода на&nbsp;открытке продукции бренда Коркунов)?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Да', 'Нет'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 7 && prevQuizValue === 1 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Уточните, персонализируете&nbsp;ли Вы&nbsp;поздравление</span> и&nbsp;отправляете&nbsp;ли его получателю?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Да', 'Нет'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 7 && prevQuizValue === 2 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Уточните, почему&nbsp;Вы не&nbsp;пользовались</span> интерактивным поздравлением?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Не знал, что оно существует', 'Не понял, как использовать', 'Не интересуюсь подобным', 'Очень долго создавать'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {it}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 8 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Как вы&nbsp;узнали</span> об&nbsp;акции?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Рекламные материалы в&nbsp;магазинах', 'Информация на&nbsp;других сайтах', 'Рассказали знакомые', 'Увидел информацию на&nbsp;сайте акции в&nbsp;конструкторе поздравлений', 'Информация с&nbsp;кешбэк акции &laquo;СберСпасибо&raquo;', 'Другое'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {parse(it)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 9 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Какой приз из&nbsp;еженедельных Вам больше всего понравится</span> в&nbsp;следующих акциях КОРКУНОВ?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Брендированные вещи', 'Техника', 'Сертификаты', 'Деньги'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {parse(it)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 10 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Если в&nbsp;акции будет участвовать брендированные вещи</span>, то&nbsp;какие позиции предпочтительны?
                                            </div>
                                            <div className="quiz__descr text-center">
                                                Переместите позиции так, чтобы вверху оказалась самая предпочтительная, а&nbsp;внизу&nbsp;та, которая совсем не&nbsp;нравится
                                            </div>
                                            <div className="quiz__drag-list">
                                                <DndProvider backend={(isMobile || isTablet) ? TouchBackend : HTML5Backend}>
                                                    {list.map((it, i) => renderItem(it, i))}
                                                </DndProvider>
                                            </div>

                                        </div>
                                    )}

                                    {quiz === 11 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Какой главный приз будет</span> Вас мотивировать участвовать?
                                            </div>
                                            <div className="quiz__grid">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Дорогая техника', 'Деньги', 'Путешествия', 'Другие призы'].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {parse(it)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {quiz === 12 && (
                                        <div className="fadeIn animated">
                                            <div className="quiz__title gradient-text text-center">
                                                <span>Готовы&nbsp;ли Вы&nbsp;предоставлять документы</span> за&nbsp;приз с&nbsp;брендингом, если он&nbsp;дороже 4&nbsp;000&nbsp;рублей?
                                            </div>
                                            <div className="quiz__grid quiz__grid--row">
                                                <div className={'quiz__grid-ct var__list' + (quizVar ? ' not-empty' : '')}>
                                                    {['Скорее да', 'Хотелось&nbsp;бы, чтобы данные призы были до&nbsp;4&nbsp;000 рублей', 'Скорее нет '].map((it, i) => (
                                                        <div onClick={()=>{
                                                            setQuizVar(i + 1);
                                                        }} className={'quiz__var d-flex align-items-center justify-content-center' + (quizVar === (i + 1) ? ' active' : '')}>
                                                            <div className="quiz__var-title">
                                                                {parse(it)}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="modal__ct-box d-flex justify-content-center">
                                    <button type="button" disabled={(((quiz !== 4 && quizVar === 0) || (quiz === 4 && Object.values(quizMultiple).filter(value => value !== null).length < 11)) && quiz !== 10)} onClick={()=>{
                                        answerQuiz();
                                    }} className="btn-ct btn-ct--quiz d-flex align-items-center justify-content-center">
                                        {quiz === 12 ? 'Завершить'  : 'Далее'}
                                    </button>
                                </div>
                            </>
                        )}

                        {!loading && end && (
                            <>
                                <div className="quiz__title text-center gradient-text">
                                    Опрос завершен
                                </div>
                                <div className="quiz__descr text-center">
                                    Наша команда благодарит вас за&nbsp;участие в&nbsp;акции и&nbsp;за&nbsp;прохождение опроса!
                                </div>
                            </>
                        )}

                        {loading && (
                            <div className="loader__wrap loader__wrap-quiz d-flex align-items-center justify-content-center">
                                <div className="loader loader--inline">
                                    <img src={require("../assets/img/loader-wh.svg").default}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quiz;
