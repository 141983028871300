import React, {useEffect, useState} from "react";
import {Link, Navigate} from 'react-router-dom'
import {useEnd, useModal, useProfile} from "../context";
import ImgCheck from "../components/modals/ImgCheck";
import CodesWidget from "../components/codes/codes-widget";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import {shareData} from "../helpers/share";
import ChangePass from "../components/modals/ChangePass";

function Cabinet() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [quizEnd, setQuizEnd] = useState(true);
    const {profile, setProfile} =  useProfile();
    const {end} = useEnd();
    const [codes, setCodes] = useState([]);
    const [codesPaging, setCodesPaging] = useState({
        total: 1,
        current_page: 1
    });

    const [prizes, setPrizes] = useState({})

    const { show, hide} = useModal();

    useEffect(() => {
        if(localStorage.getItem('auth_key')){
            if(!profile.isLoading && profile.data){
                setLoading(false);

                if(profile.data.lastQuiz && profile.data.lastQuiz.question_id){
                    if(parseInt(profile.data.lastQuiz.question_id) < 12){
                        setQuizEnd(false);
                    }
                }else{
                    setQuizEnd(false);
                }
            }
        }
    },[profile]);

    useEffectOnce(()=>{
        if(localStorage.getItem('auth_key')){
            getCodes();
            getPrizes();
        }
    });

    const getCodes = async(page = 1) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('getCodes?page=' + page);
            if(response.data.result){
                setCodes(response.data.data.rows);
                setCodesPaging(response.data.data.paging);
                setLoading(false);
            }
        } catch (error) {
            if(error.response.data){
                setLoading(false);
            }
        }
    }

    const getPrizes = async() => {
        try {
            const response = await axiosInstance.get('getPrizes');
            if(response.data.result){
                /*let _prizes = {
                    1: false,
                    2: false
                }
                Object.keys(response.data.data).map((prize, i) => {
                    _prizes[response.data.data[prize]] = true;
                });*/

                setPrizes(response.data.data);
            }
        } catch (error) {
            if(error.response.data){

            }
        }
    }

    const pagination = (c, m) => {
        let current = c,
            last = m,
            delta = 1,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(codesPaging.current_page), codesPaging.total);
        if(codesPaging.total > 1){
            return(
                <div className="pager d-flex align-items-center justify-content-center">
                    <div onClick={()=>{
                        if(codesPaging.current_page !== 1){
                            getCodes(parseInt(codesPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((codesPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="49" height="49" rx="24.5" transform="matrix(1 0 0 -1 0 49.5)" fill="#720E39"/>
                            <path d="M28 16.4999L20 24.9999L28 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="page__list d-flex align-items-center">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    getCodes(parseInt(page))
                                }} className={'pager__item ' + ((page == codesPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(codesPaging.total !== codesPaging.current_page){
                            getCodes(parseInt(codesPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((codesPaging.total === codesPaging.current_page) ? 'disabled' : '')}>
                        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="49" y="49.5" width="49" height="49" rx="24.5" transform="rotate(180 49 49.5)" fill="#720E39"/>
                            <path d="M21 16.4999L29 24.9999L21 33.4999" stroke="white" strokeWidth="2"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/" replace />)
    }

    return (
        <div className="content">
            <div className="sc__lk-area">
                <div className="lk__decor-1"/>
                <div className="lk__decor-2"/>
                <div className="lk__decor-3"/>
                <div className="lk__decor-4"/>
                <div className="lk__decor-5"/>
                <div className="lk__decor-6"/>
                <div className="lk__decor-7"/>
                <div className="sc__lk">
                    <div className="container">
                        <div className="sc__header d-flex justify-content-center">
                            <div className="sc__title-wrap">
                                <div className="sc__title gradient-text">
                                    личный <span>кабинет</span>
                                </div>
                                <span className="star__light-decor"/>
                                <span className="star__light-decor star__light-decor--2"/>
                            </div>
                        </div>
                        {!profile.isLoading && Object.keys(profile.data).length > 0 && (
                            <div className="lk__wrap fadeIn animated">
                                <div className="lk__data-list">
                                    <div className="lk__data-col">
                                        <div className={'lk__data-profile' + (navigator.share ? ' can-share' : '')}>
                                            <div className="sc__title sc__title--small gradient-text">
                                                персональные <span>данные</span>
                                            </div>
                                            <div className="lk__profile-data">
                                                {profile.data.profile.name && (
                                                    <div className="sc__subtitle lk__profile-data-it text-center">
                                                        {profile.data.profile.name}
                                                    </div>
                                                )}
                                                <div className="sc__subtitle lk__profile-data-it text-center">
                                                    {profile.data.profile.email}
                                                </div>
                                            </div>
                                            {navigator.share && (
                                                <div className="d-flex justify-content-center">
                                                    <div onClick={()=>{
                                                        shareData('Поздравьте тех, кто научил важному', 'https://promo.korkunov.ru');
                                                    }} className="btn-ct d-flex align-items-center justify-content-center">
                                                        рассказать об акции
                                                    </div>
                                                </div>
                                            )}

                                            {!quizEnd && (
                                                <div className="d-flex justify-content-center quiz__btn-wrap">
                                                    <Link to="/quiz" className="btn-ct btn-ct--dark d-flex align-items-center justify-content-center">
                                                        Пройти опрос
                                                    </Link>
                                                </div>
                                            )}
                                            {/*<div className="btn__lk-dropdown mb-only">
                                                <div onClick={()=>{
                                                    show(<ChangePass/>, "modal--form modal--pass");
                                                }} className="btn__lk-dropdown-it d-flex align-items-center">
                                                    <span>
                                                       Сменить пароль
                                                   </span>
                                                </div>
                                                <a href={'https://t.me/KKV_tgbot?start=' + profile.data.promocode} target="_blank" className="btn__lk-dropdown-it d-flex align-items-center">
                                                    <span>
                                                       Связать кабинет с&nbsp;чат-ботом акции
                                                   </span>
                                                </a>

                                                <div onClick={()=>{
                                                    localStorage.removeItem('auth_key');
                                                    window.location.reload();
                                                }} className="btn__lk-dropdown-it d-flex align-items-center">
                                                    <span>
                                                       Выход
                                                   </span>
                                                </div>
                                            </div>*/}
                                        </div>


                                    </div>
                                    <div className="lk__data-col">
                                        <div className="lk__data-count">
                                            <div className="sc__title sc__title--small gradient-text">
                                                количество <br/>
                                                <span>зарегистрированных чеков</span>
                                            </div>
                                            <div className="lk__data-count-value d-flex align-items-center justify-content-center">
                                                {profile.data.countChecks}
                                            </div>
                                            {!end.isLoading && !end.end && (
                                                <div className="d-flex justify-content-center">
                                                    <CodesWidget title="зарегистрировать чек" classesList="btn-ct text-nowrap d-flex align-items-center justify-content-center"/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {profile.isLoading && (
                            <div className="loader--inline fadeIn animated d-flex align-items-center justify-content-center">
                                <img src={require("../assets/img/loader-wh.svg").default}/>
                            </div>
                        )}
                    </div>
                </div>
                {!profile.isLoading && Object.keys(profile.data).length > 0 && (
                    <div className="sc__lk-ct fadeIn animated">
                        <div className="container">
                            <div className="sc__lk-box lk--codes">
                                <div className="sc__header d-flex justify-content-center">
                                    <div className="sc__title-wrap">
                                        <div className="sc__title gradient-text">
                                            Загруженные <span>чеки</span>
                                        </div>
                                        <span className="star__light-decor"/>
                                        <span className="star__light-decor star__light-decor--2"/>
                                    </div>
                                </div>
                                <div className="lk__wrap">
                                    <div className={'tb tb--lk' + (loading ? ' tb--loading' : '')}>
                                        {codes.length > 0 && (
                                            <>
                                                <div className="tb__thead desk-only">
                                                    <div className="tb__row">
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                № чека
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                Дата загрузки
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                Статус чека
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                Приз
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tb__tbody">
                                                    {codes.map((code, i) => (
                                                        <div className="tb__row">
                                                            <div className="tb__col">
                                                                <div className="tb__td d-flex align-items-center">
                                                                    {code.photos && code.photos.length > 0 && (
                                                                        <a onClick={()=>{
                                                                            show(<ImgCheck
                                                                                close={hide}
                                                                                img={code.photos}
                                                                                check_fp={code.qr ? code.qr.fp : null}
                                                                                check_fn={code.qr ? code.qr.fn : null}
                                                                                check_fd={code.qr ? code.qr.fd : null}
                                                                            />, "modal--form modal--img");
                                                                        }}  className="tb__img">
                                                                            <img src={code.photos[0]}/>
                                                                        </a>
                                                                    )}
                                                                    <span>{code.key}</span>
                                                                </div>
                                                            </div>
                                                            <div className="tb__col">
                                                                <div className="tb__td">
                                                                    {code.date_create}
                                                                </div>
                                                            </div>
                                                            <div className="tb__col">
                                                                <div className="tb__td d-flex align-items-center">
                                                                    <i className={'icon__' + code.status.type}></i>
                                                                    <span>{code.status.text}</span>

                                                                    {((code.status.type === 'bad' || code.status.type === 'rejected' || code.status.type === 'fail') && code.status.rejectReason) && (
                                                                        <div className="hint">
                                                                            <div className="hint__toggle">
                                                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle cx="11" cy="11" r="11" fill="#822147"/>
                                                                                    <path d="M11.9239 13.7407H10.2719V13.2087C10.2719 12.3127 10.4959 11.8367 11.1959 11.1647L11.8259 10.5627C12.1759 10.2407 12.4699 9.87669 12.4699 9.31669C12.4699 8.58869 11.9799 8.02869 11.1539 8.02869C10.2859 8.02869 9.75391 8.72869 9.72591 9.48469L8.00391 9.33069C8.21391 7.55269 9.54391 6.50269 11.2659 6.50269C12.8759 6.50269 14.2479 7.44068 14.2479 9.20468C14.2479 10.3947 13.7159 10.9267 12.8479 11.6827C12.1199 12.3267 11.9239 12.5647 11.9239 13.3767V13.7407ZM9.99191 15.6867C9.99191 15.0847 10.4959 14.5947 11.1119 14.5947C11.7139 14.5947 12.2319 15.0567 12.2319 15.6587C12.2319 16.2607 11.7279 16.7507 11.1119 16.7507C10.5239 16.7507 9.99191 16.2887 9.99191 15.6867Z" fill="white"/>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="hint__popover">
                                                                                {code.status.rejectReason}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="tb__col">
                                                                <div className="tb__td">
                                                                    {/*{(typeof code.prize === 'object' && !Array.isArray(code.prize)) ? code.prize.text : ((Array.isArray(code.prize) && code.prize.length > 0) ? code.prize[0].text : '')}*/}

                                                                    {(typeof code.prize === 'object' && !Array.isArray(code.prize)) && code.prize.text}

                                                                    {((Array.isArray(code.prize) && code.prize.length > 0) && (
                                                                        <>
                                                                            <div>
                                                                                {code.prize[0].text}
                                                                            </div>
                                                                            {code.prize[0].prize_link && (
                                                                                <div className="d-flex tb__td-btn">
                                                                                    <a href={code.prize[0].prize_link} target="_blank" download className="btn-ct btn-ct--small d-flex align-items-center justify-content-center">
                                                                                        <span>Скачать сертификат</span>
                                                                                    </a>
                                                                                </div>
                                                                            )}

                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}

                                        {codes.length === 0 && (
                                            <div className="tb__empty d-flex align-items-center justify-content-center">
                                                Пока нет загруженных чеков
                                            </div>
                                        )}

                                        {codesPaging.total > 1 && (
                                            renderPager()
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="sc__lk-box lk--prizes">
                                <div className="sc__header d-flex justify-content-center">
                                    <div className="sc__title-wrap">
                                        <div className="sc__title gradient-text">
                                            Мои <span>призы</span>
                                        </div>
                                        <span className="star__light-decor"/>
                                        <span className="star__light-decor star__light-decor--2"/>
                                    </div>
                                </div>
                                <div className="prizes__list">
                                    <div className="prizes__it prizes__it--1">
                                        <div className={'prizes__it-bl' + ((!prizes[101] || prizes[101].type !== 'win') ? ' disabled' : '')}>
                                            <div className="prizes__it-img"/>
                                            <div className="prizes__it-ct">
                                                <div className="prizes__it-value">
                                                    2025
                                                </div>
                                                <div className="prizes__it-title">
                                                    Рублей
                                                </div>
                                            </div>
                                            <div className="star__decor star__decor--prize"/>
                                        </div>
                                        {profile.data.countChecks > 0 && (
                                          <>
                                              {prizes[101] && prizes[101].type === 'win' && (
                                                  <>
                                                      {!prizes[101].prize_link && (
                                                          <div className="prizes__it-hint text-center">
                                                              Приз выигран
                                                          </div>
                                                      )}

                                                      {prizes[101].prize_link && (
                                                          <div className="d-flex justify-content-center">
                                                              <a href={prizes[101].prize_link} target="_blank" download className="btn-ct btn-ct--small d-flex align-items-center justify-content-center">
                                                                  <span>Скачать сертификат</span>
                                                              </a>
                                                          </div>
                                                      )}
                                                  </>
                                              )}

                                              {(!prizes[101] || prizes[101].type !== 'win') && (
                                                  <div className="prizes__it-hint text-center">
                                                      Вы участвуете в&nbsp;розыгрыше
                                                  </div>
                                              )}
                                          </>
                                        )}

                                        {profile.data.countChecks === 0 && (
                                            <div className="prizes__it-hint text-center">
                                                Регистрируйте чеки для участия в&nbsp;розыгрыше
                                            </div>
                                        )}
                                    </div>
                                    <div className="prizes__it prizes__it--2">
                                        <div className={'prizes__it-bl' + ((!prizes[201] || prizes[201].type !== 'win') ? ' disabled' : '')}>
                                            <div className="prizes__it-img"/>
                                            <div className="prizes__it-ct">
                                                <div className="prizes__it-value gradient-text">
                                                    500 000
                                                </div>
                                                <div className="prizes__it-title gradient-text">
                                                    Рублей <br/>
                                                    на исполнение <br/>
                                                    мечты
                                                </div>
                                            </div>
                                            <div className="star__decor star__decor--prize-large"/>
                                        </div>
                                        {profile.data.countChecks > 0 && (
                                            <>
                                                {prizes[201] && prizes[201].type === 'win' && (
                                                    <div className="prizes__it-hint text-center">
                                                        Ожидайте запрос документов <br/>
                                                        с почты korkunov@tma-draft.com
                                                    </div>
                                                )}

                                                {(!prizes[201] || prizes[201].type !== 'win') && (
                                                    <div className="prizes__it-hint text-center">
                                                        Вы участвуете в&nbsp;розыгрыше
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {profile.data.countChecks === 0 && (
                                            <div className="prizes__it-hint text-center">
                                                Регистрируйте чеки для участия в&nbsp;розыгрыше
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <div className="hidden">
                <div id="update-codes" onClick={()=>{
                    getCodes(1);
                }}></div>
                <div id="inc-codes" onClick={()=>{
                    setProfile({
                        ...profile,
                        data: {
                            ...profile.data,
                            countChecks: (profile.data.countChecks + 1)
                        }
                    });
                }}></div>
            </div>
        </div>
    );
}

export default Cabinet;
