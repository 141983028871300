import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ProfileProvider, ModalProvider, EndProvider} from "./context";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Cabinet from "./pages/Cabinet";
import Quiz from "./pages/Quiz";
import {Helmet} from "react-helmet";
import Winners from "./pages/Winners";
import WishCard from "./pages/WishCard";

import { TrackJS } from "trackjs";
TrackJS.install({
    token: "a13b8cbc8a3943aba18078ce4216efbd",
});

function App() {
  return (
      <>
          <Helmet>
              <title>Чудеса в Ваших руках</title>
              <meta property="og:title" content="Чудеса в Ваших руках"/>
          </Helmet>
          <EndProvider>
              <ProfileProvider>
                  <ModalProvider>
                      <div id="wrapper" className="wrapper">
                          <Header/>
                          <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/cabinet" element={<Cabinet />} />
                              <Route path="/wish-card" element={<WishCard />} />
                              <Route path="/winners" element={<Winners />} />
                              <Route path="/quiz" element={<Quiz />} />
                          </Routes>
                          <Footer/>
                      </div>
                  </ModalProvider>
              </ProfileProvider>
          </EndProvider>

      </>
  );
}

export default App;
