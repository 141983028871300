import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useModal, useProfile} from "../context";
import Feedback from "./modals/Feedback";
function Footer({screen, moveDown}) {
    const location = useLocation();
    const {profile} =  useProfile();
    const [inner, setInner] = useState(false);
    const { show, hide} = useModal();

    useEffect(() => {
        setInner(location.pathname != '/');
    }, [location.pathname]);

    return (
        <>
            <div className={'footer' + (location.pathname === '/end' ? ' footer--thin' : '')}>
                <div className="container">
                    <div className="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.mars.com/privacy-policy-russian" target="_blank">Конфиденциальность</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/legal-russia" target="_blank">Юридические условия</a>
                            </li>
                            <li>
                                <a href="https://mars.com/" target="_blank">Владелец сайта</a>
                            </li>
                            <li>
                                <a onClick={()=>{
                                    show(<Feedback product={true}/>, "modal--form modal--feedback");
                                }}>Задать вопрос о продукте</a>
                            </li>
                            <li>
                                <a href="/user-agreement.pdf" target="_blank">Пользовательское соглашение</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/cookies-russia" target="_blank">Cookies</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__legal text-center">
                        Общий срок акции (включая срок выдачи призов): с&nbsp;01.11.2024 по&nbsp;28.02.2025. Срок совершения покупок и&nbsp;регистрации чеков: с&nbsp;01.11.2024 по&nbsp;07.01.2025. Количество призов ограничено. Полная информация об&nbsp;организаторе акции, правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения размещена на&nbsp;promo.korkunov.ru.
                    </div>
                    <div className="footer__rights">
                        © 2024 Mars, Incorporated. Все права защищены
                    </div>
                </div>
                {location.pathname == '/' && (
                    <div onClick={()=>{
                        document.getElementById('promo').scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                    }} className="up d-flex align-items-center justify-content-center">
                        <div className="up__ct">
                            <div className="up__icon">
                                <svg width="19" height="29" viewBox="0 0 19 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.75 7.69643C16.6295 7.69643 11.7857 6.35714 9.375 1L9.375 28.1205" stroke="#8E1348" strokeWidth="2"/>
                                    <path d="M-2.9271e-07 7.69643C2.12054 7.69643 6.96429 6.35714 9.375 1L9.375 28.1205" stroke="#8E1348" strokeWidth="2"/>
                                </svg>
                            </div>
                            <div className="up__title text-center">
                                Наверх
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Footer;
