import React, {useEffect, useLayoutEffect, useState} from "react";
import parse from "html-react-parser";
import {useEnd, useModal, useProfile} from "../context";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useKeenSlider } from "keen-slider/react"
import anime from 'animejs';
import {gsap, Power2} from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "keen-slider/keen-slider.min.css"
import {yupResolver} from "@hookform/resolvers/yup";
import {CustomScroll} from 'react-custom-scroll';
import Feedback from "../components/modals/Feedback";
import {findGetParameter} from "../helpers/find-get-param";
import {goToAnchor} from "../helpers/go-to-anchor";
import CodesWidget from "../components/codes/codes-widget";
import Reg from "../components/modals/Reg";
import ChangePass from "../components/modals/ChangePass";

gsap.registerPlugin(ScrollTrigger);
const faq = [
    {
        title: 'Кто является организатором акции?',
        answer: 'Организатором акции является ООО &laquo;ТМА Маркетинг Сервисез&raquo;.'
    },
    {
        title: 'Где проводится акция?',
        answer: 'Акция проводится на&nbsp;территории Российской Федерации в&nbsp;сети Интернет посредством Сайта Акции и&nbsp;чат-бота Акции'
    },
    {
        title: 'Какой общий срок проведения акции?',
        answer: 'С&nbsp;01.11.2024 года по&nbsp;28.02.2025 года (включительно), включая период выдачи призов победителям'
    },
    {
        title: 'Какой срок регистрации чеков?',
        answer: 'C&nbsp;01.11.2024 года по&nbsp;07.01.2025 года (включительно).'
    },
    {
        title: 'Как зарегистрировать чек?',
        answer: 'В&nbsp;период с&nbsp;01.11.2024 по&nbsp;07.01.2025 необходимо совершить нижеперечисленные действия: <br/>' +
            '&mdash;&nbsp;приобрести продукцию из&nbsp;ассортимента бренда КОРКУНОВ<sup class="reg">&reg;</sup> ;<br/>' +
            '&mdash;&nbsp;зарегистрироваться в&nbsp;качестве участника Акции на&nbsp;Сайте Акции или в&nbsp;чат-боте Акции<br/>' +
            '&mdash;&nbsp;отсканировать QR-код;<br/>' +
            '&mdash;&nbsp;успешно пройти проверку чека<br/>' +
            'Подробнее в&nbsp;<a href="/rules.pdf" target="_blank">правилах акции</a>.'
    },
    {
        title: 'Какие призы возможно выиграть в&nbsp;акции?',
        answer: 'Призовой фонд акции составляют следующие призы: <br/>' +
            'Ежедневные: <br/>' +
            '&bull; Виртуальная подарочная карта myGift номиналом 2025 рублей<br/>' +
            'Главный приз:<br/>' +
            '&bull; Денежный приз номиналом 500&nbsp;000&nbsp;руб.<br/>' +
            'Полная информация о&nbsp;количестве призов и&nbsp;периодах розыгрышей указана в&nbsp;правилах акции.'
    },
    {
        title: 'Сколько призов я&nbsp;могу получить?',
        answer: 'Один участник за&nbsp;весь срок проведения акции может получить не&nbsp;более:<br/>' +
            '&bull;\t1&nbsp;(Одного) Ежедневного приза за&nbsp;весь период проведения Акции; <br/>' +
            '&bull;\t1&nbsp;(Одного) Главного приза за&nbsp;весь период проведения Акции;'
    },
    {
        title: 'Где я&nbsp;могу посмотреть выигранные мною призы?',
        answer: 'Статус возможно отслеживать в&nbsp;личном кабинете на&nbsp;сайте Акции и&nbsp;в&nbsp;Ассистенте Акции в&nbsp;разделе &laquo;Мои призы&raquo;'
    },
    {
        title: 'Как узнать, стал&nbsp;ли я&nbsp;обладателем приза?',
        answer: 'Информация будет доступна на&nbsp;сайте акции в&nbsp;разделе &laquo;Победители&raquo;'
    },
    {
        title: 'Я&nbsp;выиграл приз, какую информацию мне нужно сообщить организатору акции для получения приза?',
        answer: 'Ежедневный приз &laquo;Виртуальная подарочная карта номиналом 2025&nbsp;руб.&raquo; не&nbsp;требует предоставления данных для его получения.<br/>' +
            'Для получения Главного приза, стоимость которого превышает 4&nbsp;000&nbsp;рублей, победитель обязан предоставить следующую информацию/документы:<br/>' +
            '&bull; ФИО, <br/>' +
            '&bull; Номер мобильного телефона, <br/>' +
            '&bull; Адрес электронной почты, <br/>' +
            '&bull; Сканированную копию паспорта гражданина Российской Федерации (основной разворот и&nbsp;страница с&nbsp;постоянной регистрацией),<br/>' +
            '&bull; Номер ИНН,<br/>' +
            '&bull; Согласие на обработку персональных данных (документ, предоставляемый Организатором Акции).<br/>'
    },
    {
        title: 'Я&nbsp;передал организатору все данные о&nbsp;себе. Когда и&nbsp;как мне пришлют приз?',
        answer: 'Ежедневные призы вручаются путем отправки на&nbsp;электронную почту Победителя.<br/>' +
            'Главный приз вручается посредством перевода на&nbsp;расчетный счет Победителя'
    },
    {
        title: 'Я могу вместо приза получить деньги?',
        answer: 'Призы не&nbsp;могут быть востребованы участниками повторно, денежная компенсация не&nbsp;выдается.'
    },
    {
        title: 'Платит&nbsp;ли налог за&nbsp;приз победитель?',
        answer: 'Нет, Организатор, при выдаче Главных призов Участникам, в&nbsp;соответствии с&nbsp;положениями Налогового Кодекса РФ&nbsp;выступает в&nbsp;отношении них налоговым агентом и&nbsp;исполняет обязанность по&nbsp;правильному и&nbsp;своевременному исчислению, удержанию из&nbsp;доходов в&nbsp;виде Призов, вручаемых Победителям, налога на&nbsp;доходы физического лиц (НДФЛ) и&nbsp;перечислению его в&nbsp;бюджет.'
    },
];
const products = [
    {
        title: 'Ассорти из&nbsp;темного <br/>и&nbsp;молочного шоколада',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 165&nbsp;г',
        img: require("../assets/img/products/1.png"),
    },
    {
        title: 'Ассорти из молочного <br/>шоколада',
        descr: 'Молочный и &nbsp;молочный с&nbsp;фундуком, 165&nbsp;г',
        img: require("../assets/img/products/2.png"),
    },
    {
        title: 'Ореховая коллекция',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 165&nbsp;г',
        img: require("../assets/img/products/3.png"),
    },
    {
        title: 'Ассорти из темного <br/>шоколада',
        descr: 'Темный, темный с&nbsp;фундуком, 165&nbsp;г',
        img: require("../assets/img/products/4.png"),
    },
    {
        title: 'Ассорти из&nbsp;темного <br/>и&nbsp;молочного шоколада',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 110&nbsp;г',
        img: require("../assets/img/products/5.png"),
    },
    {
        title: 'Ассорти из&nbsp;молочного <br/>шоколада',
        descr: 'Молочный и&nbsp;молочный с&nbsp;фундуком, 110&nbsp;г',
        img: require("../assets/img/products/6.png"),
    },
    {
        title: 'Ассорти из&nbsp;темного <br/>и&nbsp;молочного шоколада',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 110&nbsp;г',
        img: require("../assets/img/products/7.png"),
    },
    {
        title: 'Ореховая коллекция',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 110&nbsp;г',
        img: require("../assets/img/products/8.png"),
    },
    {
        title: 'коллекция шоколадных <br/>плиток',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 87&nbsp;г',
        img: require("../assets/img/products/9.png"),
    },
    {
        title: 'коллекция шоколадных <br/>плиток',
        descr: 'Темный, молочный и&nbsp;молочный с&nbsp;фундуком, 131&nbsp;г',
        img: require("../assets/img/products/10.png"),
    },
    {
        title: 'новогодний набор конфет',
        descr: 'Коллекция шоколадных конфет из&nbsp;темного и&nbsp;молочного шоколада, с&nbsp;цельным и&nbsp;дробленым фундуком, вафельной крошкой, светлой и&nbsp;темной ореховой начинкой, 73&nbsp;г',
        img: require("../assets/img/products/11.png"),
    },
    {
        title: 'новогодний набор конфет',
        descr: 'Коллекция шоколадных конфет из&nbsp;темного и&nbsp;молочного шоколада, с&nbsp;цельным и&nbsp;дробленым фундуком, вафельной крошкой, светлой и&nbsp;темной ореховой начинкой, 100&nbsp;г',
        img: require("../assets/img/products/12.png"),
    },
];

function Home() {
    const [faqCur, setFaqCur] = useState(0);
    const {profile} =  useProfile();
    const {end} = useEnd();

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    useLayoutEffect(()=>{
        anim_promo();
    }, []);

    const anim_promo = () => {
        const fadeElems = document.querySelectorAll('.fade-anim');
        fadeElems.forEach((box) => {
            gsap.to(box, {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: box,
                    start: 'top-=' + window.innerHeight * 0.8 + 'px',
                },
                delay: box.dataset.delay ?? 0,
                duration: 0.75,
                onComplete: ()=> {
                    if(box.classList.contains('steps__it--3') && document.querySelector('.sc__divider--1')){
                        document.querySelector('.sc__divider--1').classList.add('active');
                    }

                    if(box.classList.contains('steps__it--3') && document.querySelector('.sc__divider--2')){
                        document.querySelector('.sc__divider--2').classList.add('active');
                    }
                }
            })
        });

        const cardsElems = document.querySelectorAll('.create__card');
        cardsElems.forEach((box) => {
            gsap.to(box, {
                opacity: 1,
                scrollTrigger: {
                    trigger: box,
                    start: 'top-=' + window.innerHeight * 0.8 + 'px',
                },
                duration: 0.75,
                onComplete: ()=> {
                    box.classList.add('active');
                }
            })
        });
    }

    const { show, hide} = useModal();

    const [curSl1, setCurSl1] = useState(0);
    const [loadedSl1, setLoadedSl1] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: (products.length - 1),
        origin: "center",
        loop: true,
        slides: {
            perView: 3,
            spacing: 0,
        },
        breakpoints: {
            '(max-width: 1023px)': {
                initial: 0,
                origin: "auto",
                slides: {
                    perView: 1,
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {
            if(window.matchMedia("(max-width: 1023px)").matches){
                setCurSl1(slider.track.details.rel);
            }else{
                setCurSl1((slider.track.details.rel + 1 < products.length ? (slider.track.details.rel + 1) : 0));
            }

        },
        created() {
            setLoadedSl1(true)
        },
    });

    useEffect(() => {
        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = findGetParameter('return') ?? findGetParameter('result');
            let auth_key = findGetParameter('auth_key');
            let hash = findGetParameter('hash');
            let identity_vk = findGetParameter('identity_vk');
            let identity_ok = findGetParameter('identity_ok');
            let identity_go = findGetParameter('identity_go');
            let identity_ya = findGetParameter('identity_ya');
            let identity_ma = findGetParameter('identity_ma');

            if(identity_vk){
                localStorage.setItem('identity_vk', identity_vk);
            }

            if(identity_ok){
                localStorage.setItem('identity_ok', identity_ok);
            }

            if(identity_go){
                localStorage.setItem('identity_go', identity_go);
            }

            if(identity_ya){
                localStorage.setItem('identity_ya', identity_ya);
            }

            if(identity_ma){
                localStorage.setItem('identity_ma', identity_ma);
            }

            if(hash) {
                localStorage.setItem('hash', hash);
                show(<Reg soc={true} hash={hash} auth_key={auth_key}/>, "modal--form modal--reg2");
                window.history.pushState({param: 'Value'}, '', '/');
            }

            if(auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                if(parseInt(result) === 1){
                    window.location.href = process.env.PUBLIC_URL + '/cabinet';
                }

                if(parseInt(result) === 2){
                    show(<ChangePass/>, "modal--form modal--pass");
                    window.history.pushState({param: 'Value'}, '', '/');
                }

            }

            if(parseInt(result) === 0){
                show(<Reg soc={true} auth_key={auth_key}/>, "modal--form modal--reg2");
                window.history.pushState({param: 'Value'}, '', '/');
            }
        }

        if(window.location.hash){
            goToAnchor(window.location.hash.substring(1));
            window.history.pushState({param: 'Value'}, '', '/');
        }
    },[]);


    return (
        <div className="content">
            <div id="promo" className="sc__promo">
                {window.matchMedia("(min-width: 1024px)").matches && (
                    <div className="promo__anim desk-only">
                        <div className="anim__box">
                            <iframe src="/animation/promo/index.html" frameBorder="0"></iframe>
                        </div>
                    </div>
                )}

                {window.matchMedia("(max-width: 1023px)").matches && (
                    <div className="promo__anim mb-only">
                        <div className="anim__box">
                            <iframe src="/animation/promo/mobile/index.html" frameBorder="0"></iframe>
                        </div>
                    </div>
                )}
                <div className="promo__bg">
                    <span className="star__light-decor mb-only"></span>
                    <span className="star__light-decor star__light-decor--2 mb-only"></span>
                    <span className="star__light-decor star__light-decor--3 mb-only"></span>
                </div>
                <div className="container d-flex align-items-center">
                    <div className="promo__ct">
                        <div className="promo__ct-header">
                            <div className="promo__title gradient-text">
                                <span>Чудеса</span> <br/>
                                в ваших руках

                                <span className="star__light-decor desk-only"></span>
                            </div>
                            <div className="promo__subtitle gradient-text2">
                                Получите шанс выиграть <br className="desk-only"/>
                                призы <br className="mb-only"/>для исполнения мечты!
                            </div>
                            <div className="promo__prize">
                                Главный приз: <span><span className="star__light-decor"/><span className="star__light-decor star__light-decor--2"/> <span className="promo__prize-text">500 000</span></span> рублей
                            </div>
                        </div>
                        <div className="star__decor star__decor--promo desk-only"/>
                        <div className="promo__bottom">
                            <div className="promo__btn-group d-flex align-items-center justify-content-between">
                                <CodesWidget
                                    title="Зарегистрировать чек"
                                    classesList="btn__promo d-flex align-items-center justify-content-center"
                                />
                                <a href={'https://t.me/KKV_tgbot' + ((!profile.isLoading && Object.keys(profile.data).length > 0) ? '?start=' + profile.data.profile.userUuid : '')} target="_blank" className="promo__btn-tg">
                                    <img src={require("../assets/img/tg.svg").default}/>
                                </a>

                                <span className="star__light-decor mb-only"></span>
                            </div>
                            <div className="promo__trigger-box d-flex align-items-center justify-content-between">
                                <div className="promo__trigger-title">
                                    Пара нажатий — и ваша новогодняя карта желаний готова. Попробуйте!
                                </div>
                                <div onClick={()=>{
                                    goToAnchor('create');
                                }} className="promo__trigger-btn d-flex align-items-center justify-content-center">
                                    Создать
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc__steps">
                <div id="steps" className="anchor"/>
                <div className="candy__decor candy__decor-1"/>
                <div className="container">
                    <div className="sc__box sc__box--steps">
                        <div className="sc__header d-flex justify-content-center">
                            <div className="sc__title text-center">
                                Как выиграть <br/>
                                <span>призы</span>
                            </div>
                        </div>
                        <div className="steps__list d-flex">
                            <div className="steps__it steps__it--1 d-flex align-items-center fade-anim" data-delay={0.1}>
                                <div className="steps__it-icon d-flex align-items-center justify-content-center">
                                    <img src={require("../assets/img/st-1-icon.svg").default}/>
                                    <span className="star__light-decor star__light-decor--2"></span>
                                </div>
                                <div className="steps__it-ct d-flex align-items-start">
                                    <div className="steps__it-numb">
                                        1.
                                    </div>
                                    <div className="steps__it-title">
                                        Покупайте <br/>
                                        Коркунов®
                                    </div>
                                </div>
                            </div>
                            <div className="steps__it steps__it--2 d-flex align-items-center fade-anim" data-delay={0.15}>
                                <div className="steps__it-icon d-flex align-items-center justify-content-center">
                                    <img src={require("../assets/img/st-2-icon.svg").default}/>
                                </div>
                                <div className="steps__it-ct d-flex align-items-start">
                                    <div className="steps__it-numb">
                                        2.
                                    </div>
                                    <div className="steps__it-title">
                                        Регистрируйте чек <br/>
                                        на сайте или <br className="desk-only"/>
                                        в <a href={'https://t.me/KKV_tgbot' + ((!profile.isLoading && Object.keys(profile.data).length > 0) ? '?start=' + profile.data.profile.userUuid : '')} target="_blank">чат-боте</a>
                                    </div>
                                </div>
                            </div>
                            <div className="steps__it steps__it--3 d-flex align-items-center fade-anim" data-delay={0.2}>
                                <div className="steps__it-icon d-flex align-items-center justify-content-center">
                                    <img src={require("../assets/img/st-3-icon.svg").default}/>
                                </div>
                                <div className="steps__it-ct d-flex align-items-start">
                                    <div className="steps__it-numb">
                                        3.
                                    </div>
                                    <div className="steps__it-title">
                                        Получите шанс <br/>
                                        выиграть призы
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center fade-anim" data-delay={0.1}>
                            <a href="/rules.pdf" target="_blank" className="btn-ct btn-ct--large btn-ct--dark d-flex align-items-center justify-content-center">
                                Правила акции
                            </a>
                        </div>
                    </div>
                    <div className="sc__divider sc__divider--1 desk-only"/>
                    <div className="sc__box sc__box--prizes">
                        <div id="prizes" className="anchor"/>
                        <div className="sc__header d-flex justify-content-center">
                            <div className="sc__title text-center">
                                <span>призы</span>
                            </div>
                        </div>
                        <div className="prizes__list">
                            <div className="prizes__it prizes__it--1 fade-anim" data-delay={0.1}>
                                <div className="prizes__it-bl">
                                    <div className="prizes__it-img"/>
                                    <div className="prizes__it-ct">
                                        <div className="prizes__it-value">
                                            2025
                                        </div>
                                        <div className="prizes__it-title">
                                            Рублей
                                        </div>
                                    </div>
                                    <div className="star__decor star__decor--prize"/>
                                </div>
                                <div className="prizes__it-subtitle text-center">
                                    каждый день
                                </div>
                            </div>
                            <div className="prizes__it prizes__it--2 fade-anim" data-delay={0.2}>
                                <div className="prizes__it-bl">
                                    <div className="prizes__it-img"/>
                                    <div className="prizes__it-ct">
                                        <div className="prizes__it-value gradient-text">
                                            500 000
                                        </div>
                                        <div className="prizes__it-title gradient-text">
                                            Рублей <br/>
                                            на исполнение мечты
                                        </div>
                                    </div>
                                    <div className="star__decor star__decor--prize-large"/>
                                </div>
                                <div className="prizes__it-subtitle text-center">
                                    главный приз
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center fade-anim" data-delay={0.1}>
                            <div onClick={()=>{
                                goToAnchor('buy');
                            }} className="btn-ct btn-ct--large btn-ct--dark d-flex align-items-center justify-content-center">
                                Купить Коркунов®
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc__create">
                <div className="star__decor star__decor--create"/>
                <div id="create" className="anchor"/>
                <div className="container d-flex align-items-center justify-content-center">
                    <div className="sc__left">
                        <div className="sc__header">
                            <div className="sc__title-wrap">
                                <div className="sc__title gradient-text2">
                                    Создайте <br/>
                                    <span>карту желаний</span>
                                </div>
                                <span className="star__light-decor"/>
                                <span className="star__light-decor star__light-decor--2"/>
                            </div>
                            <div className="create__sep desk-only"/>
                            <div className="sc__subtitle">
                                Любая мечта может исполниться! Сделайте шаг ей навстречу
                            </div>
                        </div>
                        <div className="d-flex desk-only">
                            <a href="/wish-card" className="btn-ct btn-ct--large d-flex justify-content-center align-items-center">
                                <span>Создать</span>
                            </a>
                        </div>
                    </div>
                    <div className="sc__right">
                        <div className="create__cards">
                            <div className="create__card create__card--left">
                                <img src={require("../assets/img/create-card.jpg")}/>
                            </div>
                            <div className="create__card create__card--right">
                                <img src={require("../assets/img/create-card.jpg")}/>
                            </div>
                            <div className="create__card">
                                <img src={require("../assets/img/create-card.jpg")}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex btn__create-wrap mb-only">
                        <a href="/wish-card" className="btn-ct btn-ct--large d-flex justify-content-center align-items-center">
                            <span>Создать</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="sc__const">
                <div id="const" className="anchor"/>
                <div className="const__decor-1"/>
                <div className="const__decor-2"/>
                <div className="container">
                    <div className="sc__header d-flex justify-content-center">
                        <div className="sc__title-wrap d-flex">
                            <div className="sc__title text-center gradient-text2">
                                Создайте <br/>
                                <span>новогоднее поздравление</span>
                            </div>
                            <span className="star__light-decor"/>
                            <span className="star__light-decor star__light-decor--2"/>
                        </div>
                    </div>
                    <div className="sc__subtitle text-center">
                        и&nbsp;исполняйте мечты вместе с&nbsp;близкими
                    </div>
                    <div className="steps__list d-flex">
                        <div className="steps__it steps__it--1 d-flex align-items-center fade-anim" data-delay={0.1}>
                            <div className="steps__it-icon d-flex align-items-center justify-content-center">
                                <img src={require("../assets/img/st-1-icon.svg").default}/>
                                <span className="star__light-decor star__light-decor--2"></span>
                            </div>
                            <div className="steps__it-ct d-flex align-items-start">
                                <div className="steps__it-numb gradient-text">
                                    1.
                                </div>
                                <div className="steps__it-title gradient-text">
                                    Покупайте <br/>
                                    конфеты Коркунов®
                                </div>
                            </div>
                        </div>
                        <div className="steps__it steps__it--2 d-flex align-items-center fade-anim" data-delay={0.2}>
                            <div className="steps__it-icon d-flex align-items-center justify-content-center">
                                <img src={require("../assets/img/st-4-icon.svg").default}/>
                            </div>
                            <div className="steps__it-ct d-flex align-items-start">
                                <div className="steps__it-numb gradient-text">
                                    2.
                                </div>
                                <div className="steps__it-title gradient-text">
                                    Сканируйте QR-код <br/>
                                    на открытке
                                </div>
                            </div>
                        </div>
                        <div className="steps__it steps__it--3 d-flex align-items-center fade-anim" data-delay={0.3}>
                            <div className="steps__it-icon d-flex align-items-center justify-content-center">
                                <img src={require("../assets/img/st-5-icon.png")}/>
                            </div>
                            <div className="steps__it-ct d-flex align-items-start">
                                <div className="steps__it-numb gradient-text">
                                    3.
                                </div>
                                <div className="steps__it-title gradient-text">
                                    Приглашайте близкого <br/>
                                    человека исполнять <br/>
                                    волшебные желания <br/>
                                    вместе
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center fade-anim" data-delay={0.1}>
                        <div onClick={()=>{
                            goToAnchor('buy');
                        }}  className="btn-ct btn-ct--large d-flex align-items-center justify-content-center">
                            Где купить?
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc__light-group">
                <div className="sc__products">
                    <div id="products" className="anchor"/>
                    <div className="container">
                        <div className="sc__header d-flex justify-content-center">
                            <div className="sc__title text-center">
                                Новогоднее настроение <br/>
                                <span>с&nbsp;продуктами КОРКУНОВ®</span>
                            </div>
                        </div>
                        <div className="prod__sl-box">
                            <div className="star__decor star__decor--prod1"/>
                            <div className="star__decor star__decor--prod2"/>
                            <div onClick={()=>{
                                instanceRef.current?.prev();
                            }} className="sl__nav sl__nav--prev">
                                <img src={require("../assets/img/sl-prev.svg").default} className="desk-only"/>
                                <img src={require("../assets/img/sl-prev-mb.svg").default} className="mb-only"/>
                            </div>
                            <div onClick={()=>{
                                instanceRef.current?.next();
                            }} className="sl__nav sl__nav--next">
                                <img src={require("../assets/img/sl-next.svg").default} className="desk-only"/>
                                <img src={require("../assets/img/sl-next-mb.svg").default} className="mb-only"/>
                            </div>
                            <div className="prod__sl">
                                <div ref={sliderRef} className="keen-slider">
                                    {products.map((pr, i) => (
                                        <div className="keen-slider__slide">
                                            <div className={'prod__sl-it' + (curSl1 === i ? ' active' : '')}>
                                                <div className="prod__sl-it-img">
                                                    <img src={pr.img}/>
                                                </div>
                                                {curSl1 === i && (
                                                    <div className="prod__sl-it-ct fadeIn animated text-center">
                                                        <div className="prod__sl-it-title">
                                                            {parse(pr.title)}
                                                        </div>
                                                        <div className="prod__sl-it-descr">
                                                            {parse(pr.descr)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="prod__sl-it-actions d-flex justify-content-center align-items-center">
                            <div onClick={()=>{
                                goToAnchor('buy');
                            }} className="btn-ct btn-ct--large btn-ct--dark d-flex align-items-center justify-content-center">
                                <span className="text-center">
                                    КУПИТЬ КОРКУНОВ®
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__divider sc__divider--2 desk-only"/>
                <div className="sc__buy">
                    <div className="buy__decor-1"/>
                    <div className="buy__decor-2"/>
                    <div className="buy__decor-3"/>
                    <div className="buy__decor-4"/>
                    <div className="buy__decor-5"/>
                    <div id="buy" className="anchor"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title text-center">
                                где <br className="mb-only"/><span>купить?</span>
                            </div>
                        </div>
                        <div className="buy__wrap d-flex justify-content-between">
                            <div className="buy__group">
                                <div className="sc__subtitle sc__subtitle--small text-center">
                                    Закажите с доставкой <br/>
                                    за 30 минут
                                </div>
                                <div className="buy__list">
                                    <div className="row justify-content-center">
                                        <div className="buy__it buy__it--8">
                                            <a href="https://kuper.ru/marketing/korkunov" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-8.svg").default}/>
                                            </a>
                                        </div>
                                        <div className="buy__it buy__it--6">
                                            <a href="https://lavka.yandex.ru/213/search?text=%D0%9A%D0%BE%D1%80%D0%BA%D1%83%D0%BD%D0%BE%D0%B2" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-6.png")}/>
                                            </a>
                                        </div>
                                         <div className="buy__it buy__it--7">
                                            <a href="https://samokat.go.link/promocategory/d28ab572-42b6-48f2-8206-ec0d05046d7b?showcaseType=MINIMARKET&adj_t=5n3ryif&adj_campaign=korkunov&adj_adgroup=2024" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-7.png")}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="buy__group">
                                <div className="sc__subtitle sc__subtitle--small text-center">
                                    Закажите с доставкой <br/>
                                    на будущее
                                </div>
                                <div className="buy__list">
                                    <div className="row justify-content-center">
                                        <div className="buy__it buy__it--2">
                                            <a href="https://www.ozon.ru/brand/a-korkunov-137689619/" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-2.png")}/>
                                            </a>
                                        </div>
                                        <div className="buy__it buy__it--3">
                                            <a href="https://market.yandex.ru/special/mars_len" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-3.png")}/>
                                            </a>
                                        </div>
                                        <div className="buy__it buy__it--4">
                                            <a href="https://www.perekrestok.ru/cat/b/13481/korkunov" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-4.svg").default}/>
                                            </a>
                                        </div>
                                        <div className="buy__it buy__it--5">
                                            <a href="https://lenta.com/action/detail/48993" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-5.png")}/>
                                            </a>
                                        </div>

                                        <div className="buy__it buy__it--8">
                                            <a href="https://kuper.ru/marketing/korkunov" target="_blank" className="buy__it-bl">
                                                <img src={require("../assets/img/shops/sh-8.svg").default}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc__faq">
                <div id="faq" className="anchor"/>
                <div className="container">
                    <div className="sc__header d-flex justify-content-center">
                        <div className="sc__title-wrap">
                            <div className="sc__title gradient-text2 text-center">
                                ВОПРОСЫ <br/>
                                <span>И ОТВЕТЫ</span>
                            </div>
                            <span className="star__light-decor"/>
                            <span className="star__light-decor star__light-decor--2"/>
                        </div>
                    </div>
                    <div className="faq__wrap d-flex align-items-start">
                        <div className="faq__left">
                            <div className="faq__scroll">
                                <CustomScroll
                                    heightRelativeToParent="100%"
                                    allowOuterScroll={true}
                                >
                                    <div className="faq__list">
                                        {faq.map((fq, i) => (
                                            <div onClick={()=>{
                                                if(faqCur === (i + 1)) {
                                                    setFaqCur(-1);
                                                }else {
                                                    setFaqCur(i + 1);
                                                }

                                            }} className={'faq__it fadeIn animated' + (faqCur == (i + 1) ? ' active' : '') + (i > 4 ? ' faq__it--hidden' : '')}>
                                                <div className="faq__it-hd d-flex align-items-start justify-content-between">
                                                    <div className="faq__it-title gradient-text">
                                                        <span>{(i + 1) + '. '}</span>
                                                        {parse(fq.title)}
                                                    </div>
                                                    <div className="faq__it-icon desk-only">
                                                        <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M-6.32254e-07 1.00056C3.05357 1.00056 10.0286 3.89342 13.5 15.4648" stroke="#FFF7DF" strokeWidth="2"/>
                                                            <path d="M-6.32254e-07 1.00056C3.05357 1.00056 10.0286 3.89342 13.5 15.4648" stroke="#FFF7DF" strokeWidth="2"/>
                                                            <path d="M27 1.00056C23.9464 1.00056 16.9714 3.89342 13.5 15.4648" stroke="#FFF7DF" strokeWidth="2"/>
                                                            <path d="M27 1.00056C23.9464 1.00056 16.9714 3.89342 13.5 15.4648" stroke="#FFF7DF" strokeWidth="2"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="faq__it-answer fadeIn animated">
                                                    <div className="faq__it-txt">
                                                        {parse(fq.answer)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CustomScroll>
                            </div>
                        </div>
                        <div className="faq__right">
                            <div className="d-flex justify-content-center desk-only">
                                <div className="sc__title-wrap">
                                    <div className="sc__title gradient-text2 text-center">
                                        Обратная <br/>
                                        <span>связь</span>
                                    </div>
                                    <span className="star__light-decor star__light-decor--3"/>
                                    <span className="star__light-decor star__light-decor--4"/>
                                </div>
                            </div>
                            <div className="sc__subtitle text-center desk-only">
                                Не нашли ответа на свой <br/>
                                вопрос? Напишите нам!
                            </div>
                            <div className="d-flex justify-content-center anim--title">
                                <div onClick={()=>{
                                    show(<Feedback/>, "modal--form modal--feedback");
                                }} className="btn-ct d-flex align-items-center justify-content-center">
                                    Задать вопрос
                                </div>
                            </div>
                            <div className="faq__decor-img desk-only">
                                <div className="star__decor star__decor--faq1"/>
                                <div className="star__decor star__decor--faq2"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="promo__bottom mb-only">
                    <div className="promo__btn-group d-flex align-items-center justify-content-between">
                        <CodesWidget
                            title="Зарегистрировать чек"
                            classesList="btn__promo d-flex align-items-center justify-content-center"
                        />
                        <a href={'https://t.me/KKV_tgbot' + ((!profile.isLoading && Object.keys(profile.data).length > 0) ? '?start=' + profile.data.profile.userUuid : '')} target="_blank" className="promo__btn-tg">
                            <img src={require("../assets/img/tg.svg").default}/>
                        </a>
                    </div>
                    <div className="promo__trigger-box d-flex align-items-center justify-content-between">
                        <div className="promo__trigger-title">
                            Пара нажатий — и ваша новогодняя карта желаний готова. Попробуйте!
                        </div>
                        <div onClick={()=>{
                            goToAnchor('create');
                        }} className="promo__trigger-btn d-flex align-items-center justify-content-center">
                            Создать
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
